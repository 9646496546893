'use client'

import { Button, Cell } from '@vinted/web-ui'

import { ITEM_UPLOAD_URL } from 'constants/routes'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

type Props = {
  primaryText: string
  onPrimaryClick: () => void
}

const OnboardingTextActions = ({ primaryText, onPrimaryClick }: Props) => {
  const breakpoints = useBreakpoint()

  return (
    <div className="u-overflow-hidden">
      <Cell styling={breakpoints.phones ? Cell.Styling.Narrow : Cell.Styling.Default}>
        <Button
          styling={Button.Styling.Filled}
          text={primaryText}
          url={ITEM_UPLOAD_URL}
          onClick={onPrimaryClick}
        />
      </Cell>
    </div>
  )
}

export default OnboardingTextActions
