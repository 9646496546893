'use client'

import { useEffect, useState } from 'react'
import { BottomSheet, Button, Cell, Dialog, Image, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { ITEM_UPLOAD_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { clickEvent } from '_libs/common/event-tracker/events'
import { BeyondFashionBannerModel } from 'types/models/beyond-fashion-banner'

import useBanners from '../../../hooks/useBanners'

type Props = {
  banner: BeyondFashionBannerModel
}

const BeyondFashion = ({ banner }: Props) => {
  const { onBannerSeen } = useBanners()
  const [isOpen, setIsOpen] = useState(true)
  const translate = useTranslate()
  const isDarkMode = useIsDarkMode()
  const breakpoints = useBreakpoint()
  const { track } = useTracking()

  useTrackAbTest(useAbTest(banner.abTest.name))

  const image = isDarkMode ? banner.darkImageUrl : banner.imageUrl

  const handleClose = () => {
    track(
      clickEvent({
        target: ClickableElement.BeyondFashionNewListerBanner,
        screen: Screen.NewsFeed,
        targetDetails: 'dismiss',
      }),
    )

    setIsOpen(false)
  }

  const handleAction = () => {
    track(
      clickEvent({
        target: ClickableElement.BeyondFashionNewListerBanner,
        screen: Screen.NewsFeed,
        targetDetails: 'upload_item',
      }),
    )
  }

  useEffect(() => {
    onBannerSeen({ type: banner.type, name: banner.name })
  }, [banner, onBannerSeen])

  const renderNavigation = () => {
    return (
      <Navigation
        right={
          <Button
            iconName={X24}
            inverse
            inline
            onClick={handleClose}
            aria={{ 'aria-label': translate('common.a11y.actions.dialog_close') }}
            testId="beyond-fashion-banner-navigation-close-button"
          />
        }
      />
    )
  }

  const renderImage = () => {
    if (!image) return null

    return (
      <div>
        <Image src={image} scaling={Image.Scaling.Contain} />
      </div>
    )
  }

  const renderTitle = () => {
    if (!banner.title) return null

    return (
      <Text
        as="p"
        type={Text.Type.Heading}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
      >
        {banner.title}
      </Text>
    )
  }

  const renderBody = () => {
    if (!banner.body) return null

    return (
      <Text as="p" alignment={Text.Alignment.Center} width={Text.Width.Parent}>
        {banner.body}
      </Text>
    )
  }

  const renderButton = () => {
    if (!banner.actions?.primary.title) return null

    return (
      <Cell>
        <Button
          theme="primary"
          styling={Button.Styling.Filled}
          url={ITEM_UPLOAD_URL}
          onClick={handleAction}
        >
          {banner.actions.primary.title}
        </Button>
      </Cell>
    )
  }

  const renderContent = () => {
    return (
      <>
        {renderNavigation()}
        {renderImage()}
        <Cell>
          {renderTitle()}
          <Spacer size={Spacer.Size.Large} />
          {renderBody()}
        </Cell>
        <Spacer size={Spacer.Size.Large} />
        {renderButton()}
      </>
    )
  }

  if (!isOpen) return null
  if (banner.abTest.group === 'off') return null

  if (breakpoints.phones) {
    return (
      <BottomSheet
        isVisible
        onClose={handleClose}
        initialHeight={BottomSheet.Height.FullHeight}
        a11yCloseIconTitle={translate('common.a11y.actions.dialog_close')}
      >
        {renderContent()}
      </BottomSheet>
    )
  }

  return (
    <Dialog
      show
      contentDimensions={{ maxWidth: '375px' }}
      closeOnOverlay
      defaultCallback={handleClose}
    >
      {renderContent()}
    </Dialog>
  )
}

export default BeyondFashion
