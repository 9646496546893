'use client'

import { Image, PromoBanner } from '@vinted/web-ui'
import classNames from 'classnames'
import { useState } from 'react'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useAsset } from '@marketplace-web/shared/ui-helpers'

import { itemUploadClickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { FirstTimeListerGuidelineModel } from 'types/models/first-time-lister-guideline'

import HowToSell from './HowToSell'

type Props = {
  banner: FirstTimeListerGuidelineModel
  uploadSessionId: string | null
}

const FirstTimeListing = ({ banner, uploadSessionId }: Props) => {
  const { track } = useTracking()
  const [isOpen, setIsOpen] = useState(false)
  const asset = useAsset('/assets/items/upload/first-time-listing')
  const breakpoints = useBreakpoint()
  const classes = classNames({
    'u-ui-margin-top-large u-ui-margin-horizontal-large': breakpoints.phones,
    'u-ui-margin-top-x-large u-ui-margin-horizontal-x-large': !breakpoints.phones,
  })

  const handleOpenClick = () => {
    track(itemUploadClickEvent({ target: ClickableElement.LearnHowModal, uploadSessionId }))

    setIsOpen(true)
  }

  const handleCloseClick = () => {
    setIsOpen(false)
  }

  return (
    <div className={classes}>
      <PromoBanner
        icon={<Image src={asset('heart-loop.svg')} />}
        title={banner.title}
        body=""
        clickable
        onClick={handleOpenClick}
        actionText={banner.actionText}
        styling={PromoBanner.Styling.Tight}
        testId="first-time-listing-banner"
      />
      <HowToSell
        isOpen={isOpen}
        onClose={handleCloseClick}
        info={banner.additionalInformation}
        uploadSessionId={uploadSessionId}
      />
    </div>
  )
}

export default FirstTimeListing
