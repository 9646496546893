'use client'

import { Fragment, ReactComponentElement, ReactNode } from 'react'
import { Card, Cell, Button } from '@vinted/web-ui'
import { v4 as uuid } from 'uuid'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

type Props = {
  title?: ReactNode
  body?: ReactNode
  prefix?: ReactNode
  actions?: Array<ReactComponentElement<typeof Button>>
  highlighted?: boolean
  children?: ReactNode
}

const Banner = ({ title, body, prefix, actions, highlighted, children }: Props) => {
  const breakpoints = useBreakpoint()

  const renderActions = () => {
    if (!actions?.length) return null

    return (
      <div className="banner-actions__container">
        {actions.map(action => (
          <Fragment key={uuid()}>{action}</Fragment>
        ))}
      </div>
    )
  }

  const renderBanner = () => {
    const suffix = renderActions()
    const content = children || body

    return (
      <Cell styling={Cell.Styling.Tight} highlighted={highlighted}>
        <Cell prefix={prefix} suffix={breakpoints.phones ? null : suffix} highlighted={highlighted}>
          {title && <div>{title}</div>}
          {content && <div>{content}</div>}
        </Cell>
        {!!suffix && (
          <div className="u-phones-only u-ui-margin-horizontal-large u-ui-margin-bottom-large">
            {suffix}
          </div>
        )}
      </Cell>
    )
  }

  if (breakpoints.phones) {
    return renderBanner()
  }

  return (
    <div className="u-fill-width">
      <Card>
        <div className="u-overflow-hidden">{renderBanner()}</div>
      </Card>
    </div>
  )
}

export default Banner
