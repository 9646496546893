'use client'

import { X24 } from '@vinted/monochrome-icons'
import { BottomSheet, Button, Cell, Image, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTranslate } from '@marketplace-web/shared/i18n'

import {
  SingleStepOnboardingModalModel,
  SingleStepOnboardingModalSectionModel,
} from 'types/models/onboarding-banner'
import { ITEM_UPLOAD_URL } from 'constants/routes'

type Props = {
  banner: SingleStepOnboardingModalModel
  onClose: () => void
  onPrimaryClick: () => void
}

const SingleStep = ({ onClose, banner, onPrimaryClick }: Props) => {
  const breakpoint = useBreakpoint()
  const translate = useTranslate()

  const handleCloseClick = () => {
    onClose()
  }

  const handleActionClick = () => {
    onPrimaryClick()
  }

  const renderNavigation = () => {
    return (
      <div className="u-ui-padding-top-small u-ui-padding-horizontal-small">
        <Navigation
          right={
            <Button
              inline
              iconName={X24}
              styling={Button.Styling.Flat}
              onClick={handleCloseClick}
              testId="single-step-onboarding-modal-close-button"
              aria={{ 'aria-label': translate('common.a11y.actions.dialog_close') }}
            />
          }
        />
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <Text type={Text.Type.HeadingXL} as="p">
        {banner.title}
      </Text>
    )
  }

  const renderSection = ({ imageUrl, title, body }: SingleStepOnboardingModalSectionModel) => {
    return (
      <div key={title} className="u-flexbox">
        <div>
          <Image src={imageUrl} size={Image.Size.XLarge} />
        </div>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XLarge} />
        <div>
          <Text type={Text.Type.Title} as="p">
            {title}
          </Text>
          <Spacer size={Spacer.Size.Regular} />
          <Text type={Text.Type.Subtitle} as="p" theme="amplified">
            {body}
          </Text>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (!banner.sections?.length) return null

    return (
      <>
        <div className="u-ui-padding-top-large u-ui-padding-right-large u-ui-padding-left-large">
          {renderTitle()}
          <Spacer size={Spacer.Size.Large} />
          <Spacer size={Spacer.Size.XLarge} />
          <SeparatedList
            separator={<div className="single-step-onboarding-modal__content-separator" />}
          >
            {banner.sections.map(renderSection)}
          </SeparatedList>
        </div>
        <Spacer size={Spacer.Size.Large} />
        <Spacer size={Spacer.Size.XLarge} />
      </>
    )
  }

  const renderActions = () => {
    return (
      <Cell styling={Cell.Styling.Default}>
        <Button url={ITEM_UPLOAD_URL} styling={Button.Styling.Filled} onClick={handleActionClick}>
          {banner.actions?.primary.title}
        </Button>
      </Cell>
    )
  }

  if (breakpoint.phones) {
    return (
      <BottomSheet isVisible onClose={handleCloseClick} initialHeight={1} closeOnOverlayClick>
        {renderNavigation()}
        {renderContent()}
        <div className="u-sticky u-bottom">{renderActions()}</div>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      show
      defaultCallback={handleCloseClick}
      closeOnOverlay
      contentDimensions={{ maxWidth: '343px' }}
    >
      {renderNavigation()}
      <div className="single-step-onboarding-modal__content">{renderContent()}</div>
      {renderActions()}
    </Dialog>
  )
}

export default SingleStep
