'use client'

import { X24 } from '@vinted/monochrome-icons'
import { BottomSheet, Button, Cell, Image, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { itemUploadClickEvent } from '_libs/common/event-tracker/events'
import {
  FirstTimeListerGuidelineInformationModel,
  FirstTimeListerGuidelineSectionModel,
} from 'types/models/first-time-lister-guideline'

type Props = {
  isOpen: boolean
  onClose: () => void
  info: FirstTimeListerGuidelineInformationModel
  uploadSessionId: string | null
}

const HowToSell = ({ isOpen, onClose, info, uploadSessionId }: Props) => {
  const { track } = useTracking()
  const breakpoint = useBreakpoint()
  const translate = useTranslate('first_time_listing_guideline')

  const handleCloseClick = () => {
    track(itemUploadClickEvent({ target: ClickableElement.CloseFirstListModal, uploadSessionId }))

    onClose()
  }

  const handleActionClick = () => {
    track(itemUploadClickEvent({ target: ClickableElement.UploadFirstListModal, uploadSessionId }))

    onClose()
  }

  const handleLearnMoreClick = () => {
    track(itemUploadClickEvent({ target: ClickableElement.FaqFirstListModal, uploadSessionId }))
  }

  const renderNavigation = () => {
    return (
      <Navigation
        right={
          <Button
            inline
            iconName={X24}
            styling={Button.Styling.Flat}
            onClick={handleCloseClick}
            testId="how-to-sell-close-button"
            aria={{ 'aria-label': translate('a11y.actions.close') }}
          />
        }
      />
    )
  }

  const renderTitle = () => {
    return (
      <Text type={Text.Type.Heading} as="p">
        {info.title}
      </Text>
    )
  }

  const renderSection = ({ imageUrl, title, body }: FirstTimeListerGuidelineSectionModel) => {
    return (
      <div key={title} className="u-flexbox">
        <div>
          <Image src={imageUrl} size={Image.Size.XLarge} />
        </div>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XLarge} />
        <div>
          <Text type={Text.Type.Title} as="p">
            {title}
          </Text>
          <Spacer size={Spacer.Size.Regular} />
          <Text type={Text.Type.Subtitle} as="p" theme="amplified">
            {body}
          </Text>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="u-ui-padding-left-large u-ui-padding-right-x2-large u-ui-padding-bottom-x2-large">
        {renderTitle()}
        <Spacer size={Spacer.Size.Large} />
        <Spacer size={Spacer.Size.XLarge} />
        <SeparatedList separator={<div className="first-time-listing-modal__content-separator" />}>
          {info.sections.map(renderSection)}
        </SeparatedList>
      </div>
    )
  }

  const renderActions = () => {
    return (
      <Cell styling={Cell.Styling.Default}>
        <Button styling={Button.Styling.Filled} onClick={handleActionClick}>
          {info.actions.upload.title}
        </Button>
        <Spacer size={Spacer.Size.Medium} />
        <Button
          url={info.actions.help.url}
          urlProps={{ target: '_blank' }}
          onClick={handleLearnMoreClick}
        >
          {info.actions.help.title}
        </Button>
      </Cell>
    )
  }

  if (!isOpen) return null

  if (breakpoint.phones) {
    return (
      <BottomSheet isVisible onClose={handleCloseClick} initialHeight={1} closeOnOverlayClick>
        {renderNavigation()}
        {renderContent()}
        <div className="u-sticky u-bottom">{renderActions()}</div>
      </BottomSheet>
    )
  }

  return (
    <Dialog show defaultCallback={handleCloseClick} closeOnOverlay>
      {renderNavigation()}
      <div className="first-time-listing-modal__content">{renderContent()}</div>
      {renderActions()}
    </Dialog>
  )
}

export default HowToSell
