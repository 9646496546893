'use client'

import { useInView } from 'react-intersection-observer'
import { useRef } from 'react'

import { impressionEvent } from '_libs/common/event-tracker/events'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import useHomeListerActivationBanner from './useHomeListerActivationBanner'
import ListerActivationBanner from './ListerActivationBanner'

type Props = ReturnType<typeof useHomeListerActivationBanner> & {
  index: number
}

const LISTER_ACTIVATION_BREAKPOINT_ITEM_COUNT = {
  phones: 1,
  tablets: 3,
  default: 4,
}

const HomeListerActivationBanner = (props: Props) => {
  const banner = props.generateListerActivationBannerItem(props.index)
  const { track } = useTracking()
  const wasSeen = useRef(false)

  const { ref } = useInView({
    onChange: inView => {
      if (!inView || wasSeen.current) return

      track(
        impressionEvent({
          id: banner.catalogId,
          position: props.index + 1,
          contentType: ListItemContentType.ListerActivationBanner,
          contentSource: ContentSource.ListerActivationBanner,
        }),
      )

      wasSeen.current = true
    },
  })

  if (!banner) return null

  return (
    <ListerActivationBanner
      banner={banner}
      onClose={() => props.handleBannerDismiss(props.index)}
      breakpointItemCount={LISTER_ACTIVATION_BREAKPOINT_ITEM_COUNT}
      ref={ref}
    />
  )
}

export default HomeListerActivationBanner
