'use client'

import { Button, Text } from '@vinted/web-ui'

import { Banner } from '@marketplace-web/shared/ui-helpers'
import { setBannerAsSeen } from 'data/api'
import { CatalogRulesBannerModel } from 'types/models/catalog-rules-banner'

import { BannerType } from '../../constants'

type Props = {
  banner: CatalogRulesBannerModel
}

const CatalogRulesBanner = ({ banner }: Props) => {
  const { title, subtitle, actionTitle, actionUrl, name } = banner

  const handleViewCatalogRulesClick = () => {
    setBannerAsSeen({ type: BannerType.CatalogRules, name })
  }

  return (
    <Banner
      title={<Text as="h1" text={title} type={Text.Type.Heading} theme="warning" />}
      body={<Text as="span" text={subtitle} html />}
      actions={[
        <Button
          text={actionTitle}
          url={actionUrl}
          styling={Button.Styling.Filled}
          onClick={handleViewCatalogRulesClick}
        />,
      ]}
    />
  )
}

export default CatalogRulesBanner
