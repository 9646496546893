'use client'

import { useRef, useState } from 'react'
import { Image, PromoBanner } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'

import { ITEM_UPLOAD_URL } from 'constants/routes'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { PromotionalListingBannerInCatalogFeedModel } from 'types/models/promotional-listing-banner-in-catalog-feed-banner'
import { clickEvent, viewEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ViewableElement } from 'constants/tracking/viewable-elements'

type Props = {
  banner: PromotionalListingBannerInCatalogFeedModel
  onClose: () => void
}

const PromotionalListingBanner = ({ banner, onClose }: Props) => {
  const [isClosed, setIsClosed] = useState(false)
  const isDarkMode = useIsDarkMode()
  const translate = useTranslate()
  const { track } = useTracking()
  const abTest = useAbTest(banner.abTest.name)
  const trackExpose = useTrackAbTestCallback()
  const wasExposed = useRef(false)

  const handleClick = () => {
    track(
      clickEvent({
        target: ClickableElement.PromotionalListingBanner,
        targetDetails: 'upload_item_promotional_listing_banner',
      }),
    )

    navigateToPage(ITEM_UPLOAD_URL)
  }

  const handleClose = () => {
    track(
      clickEvent({
        target: ClickableElement.PromotionalListingBanner,
        targetDetails: 'close_promotional_listing_banner',
      }),
    )

    setIsClosed(true)
    onClose()
  }

  const handleInViewChange = (inView: boolean) => {
    if (!inView) return
    if (!wasExposed.current) {
      trackExpose(abTest)
      wasExposed.current = true
    }

    if (banner.abTest.group.toLowerCase() === 'off') {
      return
    }

    track(viewEvent({ target: ViewableElement.PromotionalListingBanner }))
  }

  const renderContent = () => {
    if (isClosed) return null
    if (!banner.title) return null
    if (!banner.body) return null
    if (!banner.actions) return null

    return (
      <PromoBanner
        icon={
          <Image
            src={isDarkMode ? banner.darkImageUrl : banner.imageUrl}
            size={Image.Size.Medium}
          />
        }
        title={banner.title}
        clickable
        onClick={handleClick}
        closable
        closeButtonAriaLabel={translate('promotional_listing_banner.a11y.actions.close')}
        onClose={handleClose}
        body={banner.body}
        actionText={banner.actions.primary.title}
        styling={PromoBanner.Styling.Tight}
        testId="promotional-listing-banner"
      />
    )
  }

  return (
    <InView onChange={handleInViewChange} threshold={0.5}>
      {renderContent()}
    </InView>
  )
}

export default PromotionalListingBanner
